<template>
  <div class="p-grid">
    <div class="p-col-12">
      <div class="card">
        <DataTable
          :value="clients"
          :paginator="true"
          class="p-datatable-customers p-datatable-striped"
          :rows="20"
          dataKey="id"
          :rowHover="true"
          :autoLayout="true"
          :loading="isLoading"
          v-model:filters="filters"
          filterDisplay="row"
          stateStorage="session"
          stateKey="clients-table-state"
          responsiveLayout="scroll"
          :rowClass="rowClass"
        >
          <template #header>
            <div class="table-header">Клиенты</div>
          </template>
          <template #empty> Клиенты не найдены.</template>
          <template #loading> Загрузка данных. Подождите, пожалуйста.</template>
          <Column
            field="uid"
            header="ID"
            :sortable="true"
            :showFilterMenu="false"
          >
            <template #body="slotProps">
              <router-link
                :to="{
                  name: 'ClientDetail',
                  params: { id: slotProps.data.uid },
                }"
                >{{ slotProps.data.uid }}</router-link
              >
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
                class="p-column-filter"
                :placeholder="``"
                v-tooltip.top.focus="''"
              />
            </template>
          </Column>
          <Column
            field="name"
            header="Название"
            :sortable="true"
            :showFilterMenu="false"
          >
            <template #body="slotProps">
              <router-link
                :to="{
                  name: 'ClientDetail',
                  params: { id: slotProps.data.uid },
                }"
              >
                <div>
                  <i
                    class="pi pi-circle-on p-mr-2"
                    style="color: red"
                    v-if="!slotProps.data.verified"
                  />
                  {{ slotProps.data.name }}
                </div>
              </router-link>
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                @input="filterCallback()"
                class="p-column-filter"
                :placeholder="``"
                v-tooltip.top.focus="''"
              />
            </template>
          </Column>
          <Column
            field="district"
            header="Район"
            :sortable="true"
            :showFilterMenu="false"
          >
            <template #body="slotProps">
              {{ slotProps.data.district }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
                class="p-column-filter"
                :placeholder="``"
                v-tooltip.top.focus="''"
              />
            </template>
          </Column>
          <Column
            field="inn"
            header="ИНН"
            :sortable="true"
            :showFilterMenu="false"
          >
            <template #body="slotProps">
              {{ slotProps.data.inn }}
            </template>
            <template #filter="{ filterModel, filterCallback }">
              <InputText
                type="text"
                v-model="filterModel.value"
                @keydown.enter="filterCallback()"
                class="p-column-filter"
                :placeholder="``"
                v-tooltip.top.focus="''"
              />
            </template>
          </Column>
        </DataTable>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import { mapGetters } from "vuex";

export default {
  name: "ClientList",
  data() {
    return {
      isLoading: false,
      filters: {
        uid: { value: null, matchMode: FilterMatchMode.CONTAINS },
        name: { value: null, matchMode: FilterMatchMode.CONTAINS },
        district: { value: null, matchMode: FilterMatchMode.CONTAINS },
        inn: { value: null, matchMode: FilterMatchMode.CONTAINS },
      },
    };
  },
  created() {
    this.loadClients();
  },
  methods: {
    rowClass(data) {
      return data.debtor === true && data.verified === false
        ? "debtor-row"
        : null;
    },
    async loadClients() {
      this.isLoading = true;
      try {
        await this.$store.dispatch("myprofiles/loadClients");
      } catch (error) {
        console.error(error);
      }
      this.isLoading = false;
    },
  },
  computed: {
    ...mapGetters({
      userRegions: "userRegions",
      userClients: "myprofiles/clients",
    }),
    clients() {
      if (this.userRegions.length) {
        return this.userClients.filter((client) => {
          return this.userRegions.some((elem) => {
            return client.regionIds.includes(elem.uid);
          });
        });
      }
      return this.$store.getters["myprofiles/clients"];
    },
  },
};
</script>

<style scoped lang="scss">
.table-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
</style>
<style>
.debtor-row {
  background-color: #ffe6e6 !important;
}
.debtor-row:hover {
  background-color: #ffcccc !important;
}
</style>
